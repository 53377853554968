import { FC } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Text } from '@nextui-org/react'
import { getCasePage } from 'utils/page'

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

type BlogNode = ArrayElement<Queries.BlogsQuery['blogs']['nodes']>

type BlogType = BlogNode & { image: any | undefined }

type BlogListProps = {
  blogs: BlogType[]
}

const BlogList: FC<BlogListProps> = ({ blogs }) => {
  return (
    <div className="mt-4">
      {blogs.map((blog) => {
        const { frontmatter, fields } = blog
        const blogImage = getImage(blog.image)

        return (
          <div
            key={blog.id}
            className="py-4 border-solid border-0 sm:border-b-0 border-b last:border-b-0 border-gray-300"
          >
            <Link to={getCasePage(fields?.slug || '')}>
              <div className="sm:flex block">
                <div className="w-32 sm:w-52 h-24 sm:h-40 flex-shrink-0">
                  {blogImage && (
                    <GatsbyImage
                      image={blogImage}
                      alt={blog?.frontmatter?.title || ''}
                    />
                  )}
                </div>
                <div className="ml-0 sm:ml-6 mt-2 sm:mt-0">
                  <Text className="m-0 pt-0" color="$accents8">
                    {frontmatter?.date}
                  </Text>
                  <Text h4 className="m-0 my-2" color="$text">
                    {frontmatter?.title}
                  </Text>
                  <Text className="m-0 mb-2">{blog.excerpt}</Text>
                  {frontmatter?.categories &&
                    frontmatter.categories.map((category) => (
                      <Text
                        key={category}
                        size="0.875rem"
                        as="span"
                        color="$accents8"
                        className="m-0 border border-solid border-gray-300 px-2 py-1 rounded mr-2"
                      >{`#${category}`}</Text>
                    ))}
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default BlogList
