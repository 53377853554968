import CategoryTag from 'components/CategoryTag'

type CategoryTagsProps = {
  categories: Array<{ category: string, count: number, active: boolean }>
  onCategoryClick: (text: string) => void
}

const CategoryTags = ({ categories, onCategoryClick }: CategoryTagsProps) => {
  return (
    <>
      {
        categories.map(
          c => (
            <CategoryTag
              key={c.category}
              text={c.category}
              count={c.count}
              active={c.active}
              onClick={onCategoryClick}
            />
          )
        )
      }
    </>
  )
}

export default CategoryTags
