import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

export const ARRAY_CONJUNCTION = '+';

export enum QueryParamType {
  Array = 'array',
  String = 'string'
}

export enum BlogsQueryKey {
  q = 'q'
}

const getQueryParams = (queryString: string) => {
  const hashMap: { [key in BlogsQueryKey]?: string } = {}

  if (queryString) {
    queryString.split('&').forEach((s) => {
      const [k, v] = s.split('=')
      if (k && (Object.values(BlogsQueryKey) as string[]).includes(k)) {
        hashMap[k as keyof typeof BlogsQueryKey] = v
      }
    })
  }

  return hashMap
}

const useQueryParam = (queryKey: BlogsQueryKey, type: QueryParamType): [string | string[] | null, (q: string) => void] => {
  const { search, pathname } = useLocation();
  const queryParams = getQueryParams(search.replace('?', ''));

  const queryString = queryParams[queryKey]

  let value = null;
  if (queryString) {
    switch (type) {
      case QueryParamType.Array:
        value = queryString.split(ARRAY_CONJUNCTION).map((s) => decodeURIComponent(s))
        break;
      case QueryParamType.String:
      default:
        value = decodeURIComponent(queryString)
    }
  }

  const handleQueryUpdate = (queryString: string) => {
    let query = '';
    Object.values(BlogsQueryKey).forEach((key) => {
      let param = ''
      if (key === queryKey) {
        param = queryString ? `${key}=${queryString}` : ''
      } else {
        param = `${key}=${queryParams[key]}`
      }

      query = query ? `&${param}` : param
    })

    const target = query ? `${pathname}?${query}` : pathname
    navigate(target)
  }

  return [value, handleQueryUpdate]
}

export default useQueryParam;
