import { FC, useEffect, useState, useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'
import { pageLinks } from 'ui'
import Seo from 'components/Seo'

import BlogList from 'components/blogs/BlogList'
import CategoryTags from 'components/blogs/CategoryTags'
import useQueryParam, {
  BlogsQueryKey,
  QueryParamType,
  ARRAY_CONJUNCTION,
} from 'components/blogs/useQueryParam'

const mapImagesIntoBlogs = (
  blogs: Queries.BlogsQuery['blogs'],
  images: Queries.BlogsQuery['images']
) => {
  return blogs.nodes.map((blog) => {
    const image = images.nodes.find((image) => {
      const blogSlug = blog?.fields?.slug?.replace('/', '')
      return blogSlug ? image.relativePath.includes(blogSlug) : false
    })

    return {
      ...blog,
      image,
    }
  })
}

const getCategories = (
  blogs: Queries.BlogsQuery['blogs'],
  activeCategories: string[]
) => {
  const categoryMap: Record<string, number> = {}

  blogs.nodes.forEach((blog) => {
    const categories = blog.frontmatter?.categories || []
    categories.forEach((category) => {
      if (category) {
        if (categoryMap[category]) {
          categoryMap[category]++
        } else {
          categoryMap[category] = 1
        }
      }
    })
  })

  return Object.keys(categoryMap).map((category) => ({
    category,
    count: categoryMap[category],
    active: activeCategories.includes(category),
  }))
}

const BlogsPage: FC<PageProps<Queries.BlogsQuery>> = ({ data }) => {
  const { images, blogs } = data
  const [activeCategories, setActiveCategories] = useState<string[]>([])
  const [categories, setCategoriesQuery] = useQueryParam(
    BlogsQueryKey.q,
    QueryParamType.Array
  ) as [string[] | null, (q: string) => void]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const blogsData = useMemo(() => mapImagesIntoBlogs(blogs, images), [])

  useEffect(() => {
    setActiveCategories(categories || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCategoryClick = (category: string) => {
    const categories = activeCategories.includes(category)
      ? activeCategories.filter((c) => c !== category)
      : [...activeCategories, category]

    setActiveCategories(categories)
    setCategoriesQuery(
      categories.map(encodeURIComponent).join(ARRAY_CONJUNCTION)
    )
  }

  const filteredBlogs = useMemo(() => {
    if (activeCategories.length > 0) {
      return blogsData.filter((blog) =>
        activeCategories.every((c) => {
          return blog.frontmatter?.categories?.includes(c)
        })
      )
    }
    return blogsData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategories.length])

  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <CategoryTags
        categories={getCategories(blogs, activeCategories || [])}
        onCategoryClick={handleCategoryClick}
      />
      <BlogList blogs={filteredBlogs} />
    </div>
  )
}

export const blogsQuery = graphql`
  query Blogs {
    site {
      siteMetadata {
        description
      }
    }

    images: allFile(filter: { relativePath: { regex: "/cover__portrait/" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.33
            transformOptions: { fit: COVER, cropFocus: ATTENTION }
            placeholder: BLURRED
          )
        }
      }
    }

    blogs: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      nodes {
        id
        excerpt(truncate: true, pruneLength: 40)
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          categories
        }
      }
    }
  }
`

export default BlogsPage

export const Head = ({ location }: PageProps<Queries.BlogsQuery>) => {
  const { pathname } = location
  const canonicalUrl = new URL(pathname, process.env.GATSBY_PUBLIC_BLOGS_ORIGIN)
    .href

  return (
    <Seo
      path={pageLinks.Cases.href}
      title={pageLinks.Cases.name}
      description="改造案例、老屋翻新分享"
    >
      <link rel="canonical" href={canonicalUrl} />
    </Seo>
  )
}
